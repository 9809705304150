import React from 'react';
import slugify from 'slugify';
import {graphql, Link} from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import * as style from '../assets/style/pages/tabs-page.module.scss';

const ServicePage = ({location, data}) => {
  const services = data.services.edges;
  const service = data.service.edges.map(({node}) => node)[0];
  const firstServiceSlug = slugify(services[0].node.title.value, {lower: true});
  const serviceSlug = slugify(service.title.value, {lower: true});
  const realizationCategories = data.categories.edges.map(({node}) => slugify(node.title.value, {lower: true}));

  const crumbs = [
    {title: 'Strona główna', url: '/'},
    {title: 'Usługi', url: `/uslugi/${firstServiceSlug}`},
    {title: service.title.value, url: `/uslugi/${serviceSlug}`}
  ];

  return (
    <Layout>
      <Seo title={service.title.value}>
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(
                ({title, url}, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `
              )}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.tabs}>
        <div className='container'>
          <div className='row'>
            <div className={`${style.tabs__navColumn} col-12 col-md-3 offset-xl-1`}>
              <strong className={style.tabs__navTitle}>Usługi</strong>
              <ul className={style.tabs__nav}>
                {services.map(({node}) => (
                  <li key={node.id}>
                    <Link to={`/uslugi/${slugify(node.title.value, {lower: true})}`} activeClassName="active">{node.title.value}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${style.tabs__contentColumn} col-12 col-md-9 col-xl-8`}>
              <div className={style.tabs__content}>
                <h1>{service.title.value}</h1>
                <div dangerouslySetInnerHTML={{__html: service.description.value}}></div>
                <div className={style.tabs__buttonsGroup}>
                  {realizationCategories.includes(slugify(service.title.value, {lower: true})) && (
                    <Button
                      as={Link}
                      to='/realizacje'
                      state={{category: slugify(service.title.value, {lower: true})}}
                      variant='primary'
                      color='#FFFFFF'
                    >
                      Realizacje
                    </Button>
                  )}
                </div>
                {(service.descriptionExtension?.value && <div dangerouslySetInnerHTML={{__html: service.descriptionExtension.value}}></div>)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    service: allCockpitServices(filter: {id: {eq: $id}}) {
      edges {
        node {
          title {
            value
          }
          description {
            value
          }
          descriptionExtension {
            value
          }
        }
      }
    }
    services: allCockpitServices {
      edges {
        node {
          id
          title {
            value
          }
        }
      }
    }
    categories: allCockpitRealizationCategories {
      edges {
        node {
          title {
            value
          }
        }
      }
    }
  }
`;

export default ServicePage;
